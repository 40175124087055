export default  {
    data () {
        return {
          text: `
                Поддерживайте вовлеченность участников в\u00A0процесс обучения
                и\u00A0повышайте процент успешно завершивших учебную программу
            `,
          cards: [
            {
              title: 'Лента новостей',
              text: `
                        Делитесь важными новостями, обновлениями учебной программы и\u00A0файлами со\u00A0всей группой.
                        Предлагайте опрос всем ушедшим участникам, давайте комментарии по\u00A0результатам теста
                        конкретному участнику и\u00A0отправляйте поздравления, завершившим ваш курс
                    `,
            },
            {
              image: {
                src: require('~/assets/img/products-westudy/involvement_1.jpg'),
                srcWebp: require('~/assets/img/products-westudy/involvement_1.webp'),
                srcTablet: require('~/assets/img/products-westudy/involvement_1_tablet.jpg'),
                srcTabletWebp: require('~/assets/img/products-westudy/involvement_1_tablet.webp'),
                srcMobile: require('~/assets/img/products-westudy/involvement_1_mobile.jpg'),
                srcMobileWebp: require('~/assets/img/products-westudy/involvement_1_mobile.webp'),
                alt: 'Лента новостей',
              },
            },
            {
              image: {
                src: require('~/assets/img/products-westudy/involvement_2.jpg'),
                srcWebp: require('~/assets/img/products-westudy/involvement_2.webp'),
                srcTablet: require('~/assets/img/products-westudy/involvement_2_tablet.jpg'),
                srcTabletWebp: require('~/assets/img/products-westudy/involvement_2_tablet.webp'),
                srcMobile: require('~/assets/img/products-westudy/involvement_2_mobile.jpg'),
                srcMobileWebp: require('~/assets/img/products-westudy/involvement_2_mobile.webp'),
                alt: 'Предсказание вероятности отказа от обучения',
              },
            },
            {
              title: 'Предсказание вероятности отказа от\u00A0обучения',
              text: `
                        Платформа Курсы от\u00A0МТС\u00A0Линк анализирует данные о\u00A0121 параметре обучения, а вы получаете автоматические
                         подсказки об\u00A0участниках, которые вероятнее всего откажутся от\u00A0дальнейшего прохождения курса
                    `,
            },
            {
              title: 'Личный кабинет ученика',
              text: `
                        Каждому ученику после регистрации на\u00A0курсе доступен личный кабинет со\u00A0всеми
                        учебными программами, которые он проходит или уже прошел ранее и\u00A0выданными сертификатами
                    `,
            },
            {
              image: {
                src: require('~/assets/img/products-westudy/involvement_3.jpg'),
                srcWebp: require('~/assets/img/products-westudy/involvement_3.webp'),
                srcTablet: require('~/assets/img/products-westudy/involvement_3_tablet.jpg'),
                srcTabletWebp: require('~/assets/img/products-westudy/involvement_3_tablet.webp'),
                srcMobile: require('~/assets/img/products-westudy/involvement_3_mobile.jpg'),
                srcMobileWebp: require('~/assets/img/products-westudy/involvement_3_mobile.webp'),
                alt: 'Личный кабинет ученика',
              },
            },
          ],
        }
    }
}
