<template>
  <div class="westudy-promotion">
    <div class="container">
      <h2 class="westudy-promotion__title">Оформление и продвижение</h2>

      <div class="westudy-promotion__image">
        <picture>
          <source :srcset="image.srcTabletWebp" type="image/webp" media="(max-width: 1010px)">
          <source :srcset="image.srcTablet" type="image/jpg" media="(max-width: 1010px)">
          <source :srcset="image.srcWebp" type="image/webp">
          <img :src="image.src" :alt="image.alt">
        </picture>
      </div>

      <div class="westudy-promotion__block">
        <span>{{ block }}</span>
      </div>

      <ul class="westudy-promotion__cards">
        <li
          v-for="(item, index) in cards"
          :key="index"
          class="westudy-promotion__cards-item"
        >
          <div class="westudy-promotion__cards-item-title" v-html="item.title"></div>
          <div class="westudy-promotion__cards-item-text">{{ item.text }}</div>
          <div class="westudy-promotion__cards-item-image">
            <picture>
              <source :srcset="item.image.srcTabletWebp" type="image/webp" media="(max-width: 991px)">
              <source :srcset="item.image.srcTablet" type="image/jpg" media="(max-width: 991px)">
              <source :srcset="item.image.srcWebp" type="image/webp">
              <img :src="item.image.src" :alt="item.image.alt" >
            </picture>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WestudyPromotionMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyPromotionMixin";

export default {
  name: 'WestudyPromotionDesktop',
  mixins: [WestudyPromotionMixin],
}
</script>

<style lang="scss" scoped>
$tablet: 1010px;

.westudy-promotion {
  background-color: #0A0311;
  padding: 64px 0;
  color: $color-white;
  border-radius: 24px;
  margin-bottom: 64px;

  @media (max-width: $tablet) {
    padding: 40px 0;
    margin-bottom: 40px;
  }

  picture {
    display: block;
    line-height: 0;
  }

  & .container {
    max-width: 1344px;
    padding: 0 40px;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: $tablet) {
      margin-bottom: 32px;
    }
  }

  &__image {
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__block {
    padding: 56px;
    border-radius: 24px;
    background-color: #1D1A20;
    margin-bottom: 32px;

    span {
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      display: flex;
      flex-direction: column;
      padding: 32px;
      background-color: #1D1A20;
      border-radius: 24px;
      min-height: 537px;

      @media (max-width: $tablet) {
        padding: 20px;
        min-height: 519px;
      }

      &:nth-child(1) {
        .westudy-promotion__cards-item-image {
          width: 448px;

          @media (max-width: $tablet) {
            width: 100%;
          }
        }
      }

      &:nth-child(2) {
        .westudy-promotion__cards-item-image {
          width: 356px;

          @media (max-width: $tablet) {
            width: 100%;
          }
        }
      }

      &-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 16px;
      }

      &-text {
        font-size: 20px;
        line-height: 27px;
      }

      &-image {
        margin-top: auto;
        border-radius: 16px;
        overflow: hidden;

        @media (max-width: $tablet) {
          max-width: 288px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
