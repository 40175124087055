<template>
  <div class="westudy-reviews">
    <div class="container">
      <ReviewsMobile
        :title="'Что о нас говорят клиенты'"
        :reviews-list="reviewsList"
        :color-card="'--grey'"
      />
    </div>
  </div>
</template>
<script>
import ReviewsMobile from '~/components/Mobile/Common/ReviewsMobile.vue'
import WestudyReviewsMixin from '@/components/Desktop/Products/Westudy/mixins/WestudyReviewsMixin'

export default {
  name: 'WestudyReviewsMobile',
  components: {
    ReviewsMobile,
  },
  mixins: [WestudyReviewsMixin],
}
</script>
<style lang="scss" scoped>
.westudy-reviews {
  background: $color-white;
  padding: 32px 0;
  border-radius: 24px;

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  ::v-deep {
    .reviews {
      padding: 0;
      &__title {
        font-family: $font-mts-compact;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
