export default {
  data() {
    return {
      reviewsList: [
        {
          imgHref: 'reviews-author-15',
          text: `После ухода иностранных компаний было важно возобновить онлайн-занятия в\u00A0течение одной-двух недель. С\u00A0МТС\u00A0Линк обучение стало более персонализированным: преподаватели редактируют и\u00A0дополняют программы, подстраиваясь под\u00A0уровень обучаемых.`,
          author: {
            name: 'Юлия Рыкова',
            position: `Заместитель директора по\u00A0технологическому развитию\u000AЭГО Университета `,
          },
        },
        {
          imgHref: 'reviews-author-16',
          text: `Платформа МТС\u00A0Линк\u00A0Курсы также помогла нам\u00A0решить задачу с\u00A0отслеживанием прогресса сотрудников — теперь сразу видно: пролистали вебинар/курс или\u00A0углубились в\u00A0тему. Стало проще создавать мероприятия, подключать участников и\u00A0выгружать статистику.`,
          author: {
            name: 'Людмила Липнер',
            position: `Руководитель учебного центра компании\u000A«Кладовая здоровья»`,
          },
        },
        {
          imgHref: 'reviews-author-17',
          text: `Платформа МТС\u00A0Линк\u00A0Курсы упростила администрирование процессов. Преподавателям удобнее планировать группы, назначать задания, оценивать работы, отслеживать прогресс и\u00A0давать обратную связь. Сейчас дистанционные курсы проходят более 20\u00A0000 участников ежегодно.`,
          author: {
            name: 'Артем Лукин',
            position: `Заместитель директора академии\u000Aпо\u00A0проектно-методической работе Worldskills`,
          },
        },
      ],
    }
  }
}
