export default {
  data() {
    return {
      image: {
        src: require('~/assets/img/products-westudy/paperwork_1.jpg'),
        srcWebp: require('~/assets/img/products-westudy/paperwork_1.webp'),
        srcTablet: require('~/assets/img/products-westudy/paperwork_1_tablet.jpg'),
        srcTabletWebp: require('~/assets/img/products-westudy/paperwork_1_tablet.webp'),
        srcMobile: require('~/assets/img/products-westudy/paperwork_1_mobile.jpg'),
        srcMobileWebp: require('~/assets/img/products-westudy/paperwork_1_mobile.webp'),
        // width: '1264',
        // height: '700',
        alt: 'Настраивайте лендинг курса, используйте визуальную айдентику вашей компании',
      },
      block:
        'Настраивайте лендинг курса, используйте визуальную айдентику вашей компании',
      cards: [
        {
          title: `Сертификаты <br>о\u00A0прохождении обучения`,
          text: `
                        Отправляйте брендированные сертификаты после прохождения
                        курса или\u00A0настройте автоматическую отправку
                    `,
          image: {
            src: require('~/assets/img/products-westudy/paperwork_2.jpg'),
            srcWebp: require('~/assets/img/products-westudy/paperwork_2.webp'),
            srcTablet: require('~/assets/img/products-westudy/paperwork_2_tablet.jpg'),
            srcTabletWebp: require('~/assets/img/products-westudy/paperwork_2_tablet.webp'),
            srcMobile: require('~/assets/img/products-westudy/paperwork_2_mobile.jpg'),
            srcMobileWebp: require('~/assets/img/products-westudy/paperwork_2_mobile.webp'),
            // width: '448',
            // height: '292',
            alt: 'Сертификаты о прохождении обучения',
          },
        },
        {
          title: `Онлайн-платежи`,
          text: `
                        Запускайте платное обучение и\u00A0принимайте платежи на\u00A0платформе МТС\u00A0Линк Курсы
                    `,
          image: {
            src: require('~/assets/img/products-westudy/paperwork_3.jpg'),
            srcWebp: require('~/assets/img/products-westudy/paperwork_3.webp'),
            srcTablet: require('~/assets/img/products-westudy/paperwork_3_tablet.jpg'),
            srcTabletWebp: require('~/assets/img/products-westudy/paperwork_3_tablet.webp'),
            srcMobile: require('~/assets/img/products-westudy/paperwork_3_mobile.jpg'),
            srcMobileWebp: require('~/assets/img/products-westudy/paperwork_3_mobile.webp'),
            // width: '356',
            // height: '292',
            alt: 'Онлайн-платежи',
          },
        },
      ],
    }
  }
}
