<template>
  <div class="v-accordion-mts">
    <div v-for="(item, index) in items" :key="index" class="v-accordion-mts__item">
      <div class="v-accordion-mts__heading" @click="toggleItem(index)">
        <slot name="btn" :item-btn="item">
          {{ item.title }}
        </slot>
        <VIconMTS v-if="icon" :name="'mts/icon-mts--arrow-accordion'"
          :class="['v-accordion-mts__heading-icon', item.isExpand ? 'is-open' : '']" :width="iconSize" :height="iconSize" fill="#1D2023"/>
      </div>
      <transition name="accordion">
        <div v-if="item.isExpand" :class="['v-accordion-mts__content', { 'show': item.isExpand }]" :key="index">
          <slot name="content" :item-content="item.content">{{ item.content }}</slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue';

export default {
  name: 'VAccordionMTS',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    icon: {
      type: Boolean,
      default: true
    },
    iconSize: {
      type: Number,
      default: 16,
    }
  },
  components: { VIconMTS },
  methods: {
    toggleItem(index) {
      this.items[index].isExpand = !this.items[index].isExpand;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-accordion-mts {
  &__item {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-icon {
      flex-shrink: 0;
      transform: rotate(180deg);
      transition: transform 0.3s;

      &.is-open {
        transform: rotate(0deg);
      }
    }
  }

  &__content {
    height: auto;
    overflow: hidden;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.2, 1, 0.2);
  max-height: 500px;
}

.accordion-enter,
.accordion-leave-to {
  max-height: 0px;
  transition: all 0.3s cubic-bezier(0.2, 1, 0.2, 1);
}
</style>
