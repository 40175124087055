<template>
  <FaqMobile :list="faqList" :title="true" />
</template>
<script>
import FaqMobile from '~/components/Mobile/Common/FaqMobile.vue'
import WestudyFAQMixin from '~/components/Desktop/Products/Westudy/mixins/WestudyFAQMixin'

export default {
  name: 'WestudyFAQMobile',
  components: {
    FaqMobile,
  },
  mixins: [WestudyFAQMixin],
}
</script>
<style lang="scss" scoped>
::v-deep {
  .faq {
    padding-top: 32px;
  }
  .faq__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
