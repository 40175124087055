export default {
    data () {
        return {
            faqList: [
                {
                    id: 1,
                    title: 'Что такое МТС Линк?',
                    isExpand: false,
                    content:
                        'МТС Линк — это экосистема сервисов для\u00A0онлайн-коммуникаций и\u00A0совместной работы команд. В\u00A0нее входят сервисы Встречи, Вебинары, Курсы, Чаты, Доски и\u00A0COMDI'
                },
                {
                    id: 2,
                    title: 'Как начать пользоваться МТС Линк?',
                    isExpand: false,
                    content:
                        'Чтобы пользоваться сервисами МТС Линк, достаточно <a href="https://mts-link.ru/signup/" target="_blank" class="faq__accordion_link">зарегистрироваться в\u00A0экосистеме</a>. После регистрации вам будет доступна бесплатная версия.<br>' +
                        'Нужно больше возможностей? Оставьте заявку на\u00A0сайте, мы оперативно с\u00A0вами свяжемся'
                },
                {
                    id: 3,
                    title: 'Можно ли пользоваться сервисами МТС Линк со\u00A0смартфона?',
                    isExpand: false,
                    content:
                        'Да, можно. Скачайте приложение МТС Линк в\u00A0App Store или Google Play и\u00A0подключайтесь к\u00A0встречам и\u00A0вебинарам со\u00A0своего мобильного'
                },
                {
                    id: 4,
                    title: 'Как скачать МТС Линк на\u00A0компьютер?',
                    isExpand: false,
                    content:
                        'Сервисы МТС Линк доступны в\u00A0браузере и в\u00A0десктопном приложении. Чтобы установить приложение на\u00A0компьютер, <a href="https://mts-link.ru/application/" target="_blank" class="faq__accordion_link">скачайте его по\u00A0ссылке</a> и\u00A0следуйте подсказкам системы'
                },
                {
                    id: 5,
                    title: 'Можно ли скачать запись видео в\u00A0МТС\u00A0Линк?',
                    isExpand: false,
                    content:
                        'Видеозапись прошедшей встречи или\u00A0вебинара можно скачать. Для\u00A0этого перейдите в\u00A0завершенное мероприятие, нажмите «Посмотреть запись», а\u00A0затем значок скачивания и\u00A0кнопку «Конвертировать». Выберите параметры конвертации, дождитесь ее окончания и\u00A0нажмите кнопку «Скачать»'
                }
            ],
            productsMessengerFaqList: [
                {
                    id: 1,
                    title: 'Где можно скачать&nbsp;МТС&nbsp;Линк&nbsp;Чаты?',
                    isExpand: false,
                    content: 'Десктопная и мобильная версии сервиса МТС&nbsp;Линк&nbsp;Чаты&nbsp;доступны&nbsp;для&nbsp;скачивания на&nbsp;странице <a class="faq__accordion_link" href="https://mts-link.ru/application/" target="_blank">https://mts-link.ru/application/.</a>'
                },
                {
                    id: 2,
                    title: 'Какими операционными системами поддерживается сервис?',
                    isExpand: false,
                    content: 'Для ПК пользователей доступны версии&nbsp;под&nbsp;Windows,&nbsp;MacOS,&nbsp;Linux.<br>Для мобильных пользователей доступны версии для&nbsp;Android и iOS.'
                },
                {
                    id: 3,
                    title: 'А есть ли возможность использовать МТС&nbsp;Линк&nbsp;Чаты&nbsp;через&nbsp;браузер?',
                    isExpand: false,
                    content: 'Да. Веб-версия доступна по ссылке: <a class="faq__accordion_link" href="https://my.mts-link.ru/chats/" target="_blank">my.mts-link.ru/chats/.</a> Рекомендуем использовать последние версии браузеров, чтобы получить доступ ко&nbsp;всем функциям мессенджера. Сервис Чаты работает&nbsp;в&nbsp;Chrome,&nbsp;Яндекс Браузере,&nbsp;Safari&nbsp;и&nbsp;Edge.<br>В браузерах Firefox и Opera сервис работает без&nbsp;поддержки функционала звонков.'
                },
                {
                    id: 4,
                    title: 'Могу ли я использовать&nbsp;МТС&nbsp;Линк&nbsp;Чаты бесплатно?',
                    isExpand: false,
                    content: 'Да, но у бесплатной версии есть ограничения связанные с&nbsp;функционалом&nbsp;и&nbsp;настройкой.<br>Рекомендуем обратиться к нашим менеджерам для&nbsp;точного расчета платной версии,&nbsp;в&nbsp;которой таких ограничений нет.'
                },
                {
                    id: 5,
                    title: 'Где можно получить больше информации о сервисе',
                    isExpand: false,
                    content: 'Рекомендуем ознакомиться с учебными материалами в&nbsp;нашей базе знаний <a class="faq__accordion_link" href="https://help.mts-link.ru/category/4163" target="_blank">https://help.mts-link.ru/category/4163.</a>'
                }
            ]
        }
    }
}
