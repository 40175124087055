export default {
    data () {
        return {
          title:
            'Организуйте полноценное смешанное обучение в\u00A0рамках одного курса на\u00A0платформе Курсы от\u00A0МТС\u00A0Линк',
          image: {
            src: require('~/assets/img/products-westudy/image_1.jpg'),
            srcWebp: require('~/assets/img/products-westudy/image_1.webp'),
            srcTablet: require('~/assets/img/products-westudy/image_1_tablet.jpg'),
            srcTabletWebp: require('~/assets/img/products-westudy/image_1_tablet.webp'),
            srcMobile: require('~/assets/img/products-westudy/image_1_mobile.jpg'),
            srcMobileWebp: require('~/assets/img/products-westudy/image_1_mobile.webp'),
            alt: 'Функционал платформы обучения МТС Линк Курсы',
          },
          cards: [
            'Готовые SCORM\u2011курсы',
            'Вебинары',
            'Интерактивные доски',
            'Онлайн<br>и офлайн-встречи',
            'Видео-файлы и\u00A0ролики с\u00A0Youtube и\u00A0Vimeo',
            'Получите доступ ко\u00A0всей статистике в\u00A0одном сервисе',
          ],
          list: [
            {
              title: 'от 5\u00A0минут',
              text: 'на создание нового курса',
            },
            {
              title: 'до 5\u00A0000',
              text: 'участников на\u00A0курсе',
            },
            {
              title: 'онлайн<br>и\u00A0офлайн ',
              text: 'в одном<br class="tablet"> окне',
            },
          ],
        }
    }
}
