<template>
  <div class="products-westudy">
    <WestudyHeadMobile />

    <div class="container">
      <WestudyHelpMobile />

      <WestudyPlatformMobile />
    </div>

    <WestudyEducationMobile />

    <div class="container">
      <WestudyMetodichkaMobile />
      <WebstudyFundtionsMobile />
    </div>

    <WestudyAnalyticsMobile />

    <div class="container">
      <WestudyInvolvementMobile />
    </div>

    <WestudyPromotionMobile />

    <div class="container">
      <WestudyTariffsMobile />
    </div>

    <WestudyReviewMobile />

    <div class="container">
      <WestudyFormMobile />
      <WestudyFAQMobile />
    </div>
    <ProductsBadgeMobile
      :href="'https://mts-link.ru/demo-reg-edu/'"
      :text="'–20% на\u00A0комплекс для\u00A0обучения'"
    />
  </div>
</template>

<script>
import WestudyHeadMobile from '@/components/Mobile/Products/Westudy/WestudyHeadMobile'
import WestudyHelpMobile from '@/components/Mobile/Products/Westudy/WestudyHelpMobile'
import WestudyPlatformMobile from '@/components/Mobile/Products/Westudy/WestudyPlatformMobile'
import WestudyEducationMobile from '@/components/Mobile/Products/Westudy/WestudyEducationMobile'
import WestudyMetodichkaMobile from '@/components/Mobile/Products/Westudy/WestudyMetodichkaMobile'
import WebstudyFundtionsMobile from '@/components/Mobile/Products/Westudy/WestudyFunctionsMobile'
import WestudyAnalyticsMobile from '@/components/Mobile/Products/Westudy/WestudyAnalyticsMobile'
import WestudyInvolvementMobile from '@/components/Mobile/Products/Westudy/WestudyInvolvementMobile'
import WestudyPromotionMobile from '@/components/Mobile/Products/Westudy/WestudyPromotionMobile'
import WestudyTariffsMobile from '@/components/Mobile/Products/Westudy/WestudyTariffsMobile'
import WestudyReviewMobile from '@/components/Mobile/Products/Westudy/WestudyReviewsMobile'
import WestudyFormMobile from '@/components/Mobile/Products/Westudy/WestudyFormMobile'
import WestudyFAQMobile from '@/components/Mobile/Products/Westudy/WestudyFAQMobile'
import ProductsBadgeMobile from '~/components/Mobile/Products/ProductsBadgeMobile.vue'

export default {
  name: 'ProductsWestudyMobile',
  components: {
    ProductsBadgeMobile,
    WestudyFAQMobile,
    WestudyFormMobile,
    WestudyReviewMobile,
    WestudyTariffsMobile,
    WestudyPromotionMobile,
    WestudyInvolvementMobile,
    WestudyAnalyticsMobile,
    WebstudyFundtionsMobile,
    WestudyEducationMobile,
    WestudyMetodichkaMobile,
    WestudyPlatformMobile,
    WestudyHelpMobile,
    WestudyHeadMobile,
  },
}
</script>

<style lang="scss" scoped>
.products-westudy {
  font-family: $font-mts-compact;
  padding-bottom: 64px;
  color: $color-dark-2;

  & .container {
    padding: 0 20px;
  }
}
</style>
