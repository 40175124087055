<template>
  <div class="westudy-reviews">
    <div class="container">
      <ReviewsDesktop
        :title="'Что о нас говорят клиенты'"
        :reviews-list="reviewsList"
        :color-card="'--grey'"
      />
    </div>
  </div>
</template>
<script>
import ReviewsDesktop from '~/components/Desktop/Common/ReviewsDesktop.vue'
import WestudyReviewsMixin from '@/components/Desktop/Products/Westudy/mixins/WestudyReviewsMixin'

export default {
  name: 'WestudyReviewsDesktop',
  components: {
    ReviewsDesktop,
  },
  mixins: [WestudyReviewsMixin],
}
</script>
<style lang="scss" scoped>
.westudy-reviews {
  padding: 64px 0;
  background: $color-white;
  border-radius: 24px;
  @include screen-down('lg') {
    padding: 40px 0;
  }
  ::v-deep {
    .reviews {
      padding: 0;
      &__title {
        font-family: $font-mts-compact;
        font-size: 32px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>
