<template>
  <Component :is="pageComponent" />
</template>
<script>
import LayoutPrimary from '@/mixins/LayoutPrimary'
import ProductsWestudyDesktop from '@/components/Page/Products/Westudy/ProductsWestudyDesktop'
import ProductsWestudyMobile from '@/components/Page/Products/Westudy/ProductsWestudyMobile'

export default {
  name: 'ProductsWestudyPage',
  mixins: [LayoutPrimary],
  head() {
    return {
      title: 'МТС Линк Курсы — платформа для онлайн обучения и курсов',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'МТС Линк Курсы – это бесплатная Российская платформа от «МТС Линк» для создания онлайн-курсов и организации дистанционного обучения. Данная платформа идеально подходит всем: студентам, сотрудникам, клиентам и партнерам, т.к. имеет мощный внутренний функционал ► Переходите на сайт и начните использовать МТС Линк Курсы прямо сейчас!',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '',
        },
        { name: 'og:url', content: 'https://mts-link.ru/products/westudy/' },
        {
          name: 'og:site_name',
          content: 'МТС Линк — российская экосистема сервисов для бизнес-коммуникаций и совместной работы | Платформа для проведения вебинаров, создания онлайн‑курсов',
        },
        {
          name: 'og:title',
          content: 'МТС Линк Курсы — платформа для онлайн обучения и курсов',
        },
        {
          name: 'og:description',
          content:
            'МТС Линк Курсы – это российская платформа от «МТС Линк» для создания онлайн-курсов и организации обучения. Данная платформа идеально подходит для обучения: студентов, сотрудников, клиентов и партнеров | Создавайте курсы и собирайте статистику:  переходите на сайт и начните использовать МТС Линк Курсы прямо сейчас!',
        },
        { name: 'og:type', content: 'website' },
        {
          name: 'og:image',
          content:
            'https://mts-link.ru/tilda/page41752091/images/tild3164-3834-4465-a235-663035613636__og_image.jpg',
        },
      ],
      bodyAttrs: {
        class: 'grey-body',
      },
    }
  },
  computed: {
    pageComponent: (ctx) =>
      ctx.$device.isMobile ? ProductsWestudyMobile : ProductsWestudyDesktop,
  },
}
</script>
