<template>
  <div class="faq">
    <h3 v-if="title" class="faq__title page-section__title">
      Часто задаваемые вопросы
    </h3>
    <VAccordionMTS :items="list || faqList" :icon-size="32">
      <template #btn="{ itemBtn }">
        <p class="faq__accordion_title">{{ itemBtn.title }}</p>
      </template>
      <template #content="{ itemContent }">
        <p class="faq__accordion_content" v-html="itemContent"></p>
      </template>
    </VAccordionMTS>
  </div>
</template>

<script>
import FaqMixin from '~/components/Desktop/Common/mixins/FaqMixin'
import VAccordionMTS from '~/components/Common/MTS/VAccordionMTS.vue'

export default {
  name: 'FaqMobile',
  components: { VAccordionMTS },
  props: {
    title: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
  },
  mixins: [FaqMixin],
}
</script>

<style lang="scss" scoped>
::v-deep(.v-accordion-mts .v-accordion-mts__item:not(:last-child)) {
  margin-bottom: 12px;
}

::v-deep(.v-accordion-mts__item) {
  border-radius: 16px;
  border-bottom: none !important;
  background-color: $color-white;
}

::v-deep(.faq__accordion_link) {
  color: #0070e5;
  text-decoration: underline;
}

.faq {
  background-color: #f2f3f7;
  max-width: 716px;
  margin: 0 auto;

  &__accordion {
    &_title {
      max-width: calc(100% - 32px);
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }

    &_content {
      margin-top: 16px;
      color: #626c77;
      font-size: 17px;
      line-height: 24px;
    }
  }
}
</style>
