export default {
    data () {
        return {
          title: 'Учебная аналитика по\u00A0курсу',
          image: {
            src: require('~/assets/img/products-westudy/image_2.jpg'),
            srcWebp: require('~/assets/img/products-westudy/image_2.webp'),
            srcTablet: require('~/assets/img/products-westudy/image_2_tablet.jpg'),
            srcTabletWebp: require('~/assets/img/products-westudy/image_2_tablet.webp'),
            srcMobile: require('~/assets/img/products-westudy/image_2_mobile.jpg'),
            srcMobileWebp: require('~/assets/img/products-westudy/image_2_mobile.webp'),
            alt: 'Учебная аналитика по курсу',
          },
          text: `
                Следите за\u00A0основными метриками курса и\u00A0динамикой прохождения учебной программы за\u00A0различные периоды.
                Подключайте свои счетчики Яндекс Метрика и\u00A0Google Analytics
            `,
        }
    }
}
