<template>
  <div class="reviews page-section">
    <h2 v-if="title" class="reviews__title page-section__title">{{ title }}</h2>
    <swiper ref="reviewsSwiper" class="reviews__card-list" :options="swiperReviewsOptions">
      <swiper-slide v-for="(card, idx) in reviewsList" :key="idx">
        <VCardMTS :class="['reviews__card', '--xs', colorCard]">
          <p class="reviews__card_text">{{ card.text }}</p>
          <div class="reviews__card_author-wrapper">
            <picture>
              <source :srcset="require(`~/assets/img/${card.imgHref}.webp`)" type="image/webp">
              <img :src="require(`~/assets/img/${card.imgHref}.jpg`)" alt="фото автора" loading="lazy" width="64"
                height="64" class="reviews__card_author-img">
            </picture>
            <div class="reviews__card_author-info">
              <span class="reviews__card_author-name">{{ card.author.name }}</span>
              <p class="reviews__card_author-position">{{ card.author.position }}</p>
            </div>
          </div>
        </VCardMTS>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import ReviewsMixin from "~/components/Desktop/Common/mixins/ReviewsMixin";
import VCardMTS from "~/components/Common/MTS/VCardMTS.vue";

import 'swiper/css/swiper.min.css';

export default {
  name: 'ReviewsMobile',
  components: { VCardMTS },
  mixins: [ReviewsMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    reviewsList: {
      type: Array,
      default: () => []
    },
    colorCard: {
      type: String,
      default: ''
    }
  },

}
</script>
<style lang="scss" scoped>
.reviews {
  padding-top: 32px;
  padding-bottom: 32px;
  font-family: $font-mts-compact;

  ::v-deep {
    .swiper-slide {
      max-width: 305px;
    }
  }

  &__card {
    min-height: 481px;

    &-list {
      overflow: visible;
    }

    &_text {
      font-size: 17px;
      line-height: 24px;
      padding-bottom: 12px;
    }


    &_author {
      &-wrapper {
        margin: auto 0 0;

        img {
          object-fit: cover;
          border-radius: 50%;
        }
      }

      &-name {
        display: block;
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 4px;
      }

      &-position {
        white-space: pre-line;
        color: #626C77;
      }
    }
  }


}
</style>
