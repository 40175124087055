<template>
  <MTSFormWrapper id="westudy-form" class="westudy-form" :image="image">
    <template #form>
      <MTSRegistrationUniversalForm
        form-name="products-westudy"
        :form-id-value="'c04d5a88-0dc7-4dd1-b5eb-f82b303cb42d'"
        :form-title="`Попробуйте бесплатно <br>платформу МТС Линк Курсы`"
        :form-description="`Получите доступ бесплатно и\u00A0запускайте свои учебные программы прямо сейчас`"
        :form-options-type="'student'"
        form-btn-text="Попробовать бесплатно"
        :show-offer-text="true"
        :form-short="true"
        :form-user-comment="'Лид с лендинга https://mts-link.ru/products/westudy/. Заявка на Free тариф'"
      />
    </template>
  </MTSFormWrapper>
</template>

<script>
import MTSRegistrationUniversalForm from '@/components/Common/Form/MTSRegistrationUniversalForm'
import MTSFormWrapper from '@/components/Common/Form/MTSFormWrapper'

export default {
  name: 'WestudyFormDesktop',
  components: { MTSRegistrationUniversalForm, MTSFormWrapper },
  data() {
    return {
      image: {
        src: require('~/assets/img/products-westudy/form_image.jpg'),
        srcWebp: require('~/assets/img/products-westudy/form_image.webp'),
        srcTablet: require('~/assets/img/products-westudy/form_image_tablet.jpg'),
        srcTabletWebp: require('~/assets/img/products-westudy/form_image_tablet.webp'),
        srcMobile: require('~/assets/img/products-westudy/form_image_mobile.jpg'),
        srcMobileWebp: require('~/assets/img/products-westudy/form_image_mobile.webp'),
        alt: 'Попробуйте бесплатно платформу МТС Линк Курсы',
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
