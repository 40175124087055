<template>
  <div class="products-westudy">
    <WestudyHeadDesktop />

    <div class="container">
      <WestudyHelpDesktop />

      <WestudyPlatformDesktop />
    </div>

    <WestudyEducationDesktop />

    <div class="container">
      <WestudyMetodichkaDesktop />
      <WebstudyFundtionsDesktop />
    </div>

    <WestudyAnalyticsDesktop />

    <div class="container">
      <WestudyInvolvementDesktop />
    </div>

    <WestudyPromotionDesktop />

    <div class="container">
      <WestudyTariffsDesktop />
    </div>

    <WestudyReviewsDesktop />

    <div  class="container">
      <WestudyFormDesktop />
      <WestudyFAQDesktop />
    </div>

    <ProductsBadgeDesktop
      :href="'https://mts-link.ru/demo-reg-edu/'"
      :text="'–20% на\u00A0комплекс для\u00A0обучения'"
    />
  </div>
</template>

<script>
import WestudyHeadDesktop from '@/components/Desktop/Products/Westudy/WestudyHeadDesktop'
import WestudyHelpDesktop from '@/components/Desktop/Products/Westudy/WestudyHelpDesktop'
import WestudyPlatformDesktop from '@/components/Desktop/Products/Westudy/WestudyPlatformDesktop'
import WestudyEducationDesktop from '@/components/Desktop/Products/Westudy/WestudyEducationDesktop'
import WestudyMetodichkaDesktop from '@/components/Desktop/Products/Westudy/WestudyMetodichkaDesktop'
import WebstudyFundtionsDesktop from '@/components/Desktop/Products/Westudy/WestudyFunctionsDesktop'
import WestudyAnalyticsDesktop from '@/components/Desktop/Products/Westudy/WestudyAnalyticsDesktop'
import WestudyInvolvementDesktop from '@/components/Desktop/Products/Westudy/WestudyInvolvementDesktop'
import WestudyPromotionDesktop from '@/components/Desktop/Products/Westudy/WestudyPromotionDesktop'
import WestudyTariffsDesktop from '@/components/Desktop/Products/Westudy/WestudyTariffsDesktop'
import WestudyReviewsDesktop from '@/components/Desktop/Products/Westudy/WestudyReviewsDesktop'
import WestudyFormDesktop from '@/components/Desktop/Products/Westudy/WestudyFormDesktop'
import WestudyFAQDesktop from '@/components/Desktop/Products/Westudy/WestudyFAQDesktop'
import ProductsBadgeDesktop from '~/components/Desktop/Products/ProductsBadgeDesktop.vue'

export default {
  name: 'ProductsWestudyDesktop',
  components: {
    ProductsBadgeDesktop,
    WestudyFAQDesktop,
    WestudyFormDesktop,
    WestudyReviewsDesktop,
    WestudyTariffsDesktop,
    WestudyPromotionDesktop,
    WestudyInvolvementDesktop,
    WestudyAnalyticsDesktop,
    WebstudyFundtionsDesktop,
    WestudyEducationDesktop,
    WestudyMetodichkaDesktop,
    WestudyPlatformDesktop,
    WestudyHelpDesktop,
    WestudyHeadDesktop,
  },
}
</script>

<style lang="scss" scoped>
.products-westudy {
  padding: 32px 0 0;
  font-family: $font-mts-compact;
  color: $color-dark-2;

  ::v-deep .container {
    max-width: 1344px;
    padding: 0 40px;
  }

  @include screen-down('lg') {
    padding-bottom: 40px;
  }
}
</style>
