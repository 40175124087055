import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper)

export default {
  data() {
    return {
      swiperReviewsOptions: {
        slidesPerView: 'auto',
        spaceBetween: 32,
        mousewheel: true,
        grabCursor: true,
        // innerHeight: 481,
        breakpoints: {
          768: {
            freeMode: true,
            spaceBetween: 32,
            // innerHeight: 348,
          },
          320: {
            spaceBetween: 12,
          },
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.reviewsSwiper.$swiper
    },
  },
}
