<template>
  <FaqDesktop :list="faqList" :title="true" />
</template>
<script>
import FaqDesktop from '~/components/Desktop/Common/FaqDesktop.vue'
import WestudyFAQMixin from '~/components/Desktop/Products/Westudy/mixins/WestudyFAQMixin'

export default {
  name: 'WestudyFAQDesktop',
  components: {
    FaqDesktop,
  },
  mixins: [WestudyFAQMixin],
}
</script>
<style lang="scss" scoped>
::v-deep {
  .faq__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>
