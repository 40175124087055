export default {
  data() {
    return {
      faqList: [
        {
          id: 1,
          title:
            'Какие преимущества есть у\u00A0сервиса для\u00A0создания курсов от\u00A0МТС\u00A0Линк?',
          isExpand: false,
          content:
            'Платформа МТС\u00A0Линк\u00A0Курсы отличается простотой использования, широким набором функций, возможностями кастомизации курсов и\u00A0аналитикой. Кроме того, платформа предлагает гибкие тарифные планы, поэтому доступна разным категориям пользователей — от\u00A0индивидуальных преподавателей до\u00A0крупных учебных заведений.',
        },
        {
          id: 2,
          title:
            'Как организовать обучение с\u00A0помощью платформы МТС\u00A0Линк\u00A0Курсы?',
          isExpand: false,
          content:
            'МТС\u00A0Линк\u00A0Курсы подходят для\u00A0смешанного и\u00A0онлайн-обучения — платформа поддерживает очные мероприятия и\u00A0вебинары, стандартные и\u00A0SCORM-уроки, а\u00A0также автоматическую выдачу сертификатов. Для\u00A0практических занятий и\u00A0синхронного взаимодействия при\u00A0организации дистанционного обучения настройте интеграции с\u00A0сервисами Вебинары и\u00A0Доски от\u00A0МТС\u00A0Линк',
        },
        {
          id: 3,
          title: 'Как создать и\u00A0разместить курс на\u00A0платформе?',
          isExpand: false,
          content:
            'Для\u00A0создания дистанционных курсов нужно зарегистрироваться в\u00A0экосистеме МТС\u00A0Линк. Организаторы могут начать с\u00A0готовых шаблонов или\u00A0собрать свой курс с\u00A0нуля, наполнить структуру учебными материалами, живыми занятиями, промежуточными и\u00A0финальными тестами. Для\u00A0удобства пользователей на\u00A0платформе есть пошаговые инструкции.',
        },
        {
          id: 4,
          title:
            'Какие инструменты доступны при\u00A0проведении онлайн-курсов?',
          isExpand: false,
          content:
            'Платформа МТС\u00A0Линк\u00A0Курсы наглядно показывает прогресс обучаемых в\u00A0журнале курса. Организаторам доступна сквозная аналитика и\u00A0статистика вовлечённости, которые помогут находить узкие места в\u00A0учебных программах и\u00A0прогнозировать отток учеников.',
        },
        {
          id: 5,
          title:
            'Какие форматы контента поддерживает платформа МТС\u00A0Линк\u00A0Курсы?',
          isExpand: false,
          content:
            'Платформа для\u00A0онлайн-курсов поддерживает популярные типы документов DOC, DOCX, PDF, TXT и\u00A0презентации PPT, PPTX и\u00A0PDF. В\u00A0курсы можно добавить изображения в\u00A0форматах JPG, PNG и GIF, аудио- и\u00A0видеоконтент: MP3, WAV, MP4. Платформа позволяет загружать внешние файлы по\u00A0ссылке — например, с\u00A0видеохостингов или\u00A0из\u00A0облачных хранилищ — а\u00A0также интегрировать SCORM-уроки.',
        },
        {
          id: 6,
          title:
            'Какие решения для\u00A0развертывания сервисов предлагает платформа МТС\u00A0Линк\u00A0Курсы?',
          isExpand: false,
          content:
            'На\u00A0платформе для\u00A0запуска курсов доступны облачное и\u00A0серверное решение. Организаторы могут выбрать облачную платформу для\u00A0быстрого развертывания или\u00A0локальное решение для\u00A0полного контроля над\u00A0данными и\u00A0безопасности. Обе инфраструктуры поддерживают интеграции с\u00A0внешними сервисами и\u00A0различные форматы контента.',
        },
      ],
    }
  },
}
