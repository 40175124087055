<template>
  <div class="faq">
    <h3 v-if="title" class="faq__title page-section__title">Часто задаваемые вопросы</h3>

    <VAccordionMTS :items="list || faqList" :icon-size="32" class="faq__accordion">
      <template #btn="{ itemBtn }">
        <span class="faq__accordion_title"> {{ itemBtn.title }}</span>
      </template>
      <template #content="{ itemContent }">
        <p class="faq__accordion_text" v-html="itemContent"></p>
      </template>
    </VAccordionMTS>
  </div>
</template>

<script>
import FaqMixin from '~/components/Desktop/Common/mixins/FaqMixin'
import VAccordionMTS from '~/components/Common/MTS/VAccordionMTS.vue'

export default {
  name: 'FaqDesktop',
  components: { VAccordionMTS },
  mixins: [FaqMixin],
  props: {
    title: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    }
  },
}
</script>

<style lang="scss" scoped>
.faq {
  $block: &;
  margin: 0 auto;
  padding: 64px 0 128px;
  width: 100%;
  max-width: 716px;

  @include screen-down ('lg') {
    padding: 40px 0;
  }

  &__accordion {

    &_title {
      display: inline-block;
      max-width: calc(100% - 32px);
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }

    &_text {
      margin-top: 16px;
      color: #626C77;
      font-size: 17px;
      line-height: 24px;
    }
  }

  ::v-deep {
    .v-accordion-mts__item {
      border: none;
      padding: 20px 24px;
      border-radius: 16px;
      background-color: $color-white;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    #{$block}__accordion_link {
      color: #0070E5;
      text-decoration: underline;
    }
  }
}
</style>
